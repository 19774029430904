import React from 'react'
import { styled, Flex } from 'ui'

interface Props {
	accent: React.ReactNode
	children: React.ReactNode
	cta: React.ReactNode
}

export const EmptyState = ({ accent, children, cta }: Props) => {
	return (
		<Flex direction='column'>
			<div>{accent}</div>
			<div>{children}</div>
			<div>{cta}</div>
		</Flex>
	)
}
