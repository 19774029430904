import React from 'react'
import {
	MenuIcon,
	MenuItem,
	styled,
	Button,
	Avatar,
	Sun,
	Moon,
	Menu,
	Plus,
} from 'ui'
import { Logo } from 'branding'
import { useTheme } from '../../styles/theme'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useSession, signIn, signOut } from 'next-auth/react'
import { Credits } from './credits'
import { trpc } from '@utils/trpc'

const Flex = styled('div', {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
})

const TransparentButton = styled('button', {
	background: 'transparent',
	border: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	width: '3rem',
	transition: 'transform 0.2s ease-in-out',
	'&:hover': {
		transform: 'scale(1.125)',
	},
})

const ThemeToggle = () => {
	const { theme, toggleTheme } = useTheme()

	return (
		<TransparentButton onClick={toggleTheme}>
			{theme === 'light' ? <Sun /> : <Moon />}
		</TransparentButton>
	)
}

const Account = () => {
	const { data: session } = useSession()
	const { data: userData } = trpc.user.get.useQuery()

	if (session) {
		return (
			<Menu
				trigger={
					<TransparentButton>
						<Avatar src={session?.user?.image || ''} fallback='DK' />
					</TransparentButton>
				}>
				<MenuItem>
					<div>Credits: {userData?.Subscription[0]?.credits || 0}</div>
				</MenuItem>
				<MenuItem>
					<Link href='/account'>My Account</Link>
				</MenuItem>
				<MenuItem onSelect={() => signOut()}>Log out</MenuItem>
			</Menu>
		)
	}
	return null
}

const Nav = styled('nav', {
	width: 'max-content',
	display: 'flex',
	position: 'fixed',
	height: '3.25rem',
	top: '1.5rem',
	left: '1.5rem',
	zIndex: 100,
	background: `linear-gradient($background, $background) padding-box,
	linear-gradient(180deg, rgba(95, 39, 255, 1) 0%, rgba(39, 100, 255, 1) 100%)`,
	border: '2px solid transparent',
	borderRadius: '3px',
	color: '$white',
})

const LogoWrapper = styled('div', {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'linear-gradient(134.95deg, #2764FF 25.02%, #5F27FF 100%)',
	// boxShadow: '0px 2.69652px 120px rgba(255, 255, 255, 0.15)',
	borderRadius: '3px 0px 0px 3px',
	height: 'calc(3rem + 4px)',
	margin: '-2px 0 -2px -2px',
	padding: '0 1.5rem',
	'& svg': {
		color: '$white',
		transition: 'transform 0.2s ease-in-out',
	},
	'&:hover svg': {
		transform: 'scale(1.05)',
	},
})

const CreateButton = styled('button', {
	borderRadius: '50%',
	height: '2rem',
	width: '2rem',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	right: '-1rem',
	background: `linear-gradient($background, $background) padding-box,
	linear-gradient(180deg, rgba(95, 39, 255, 1) 0%, rgba(39, 100, 255, 1) 100%)`,
	border: '2px solid transparent',
	transition: 'all 0.15s ease-in-out',
	'&:hover': {
		background: `linear-gradient(180deg, rgba(95, 39, 255, 1) 0%, rgba(39, 100, 255, 1) 100%)`,
		transform: 'scale(1.125)',
		border: 'none',
		'& svg': {
			color: '$white',
		},
	},
})

export const Header = () => {
	const router = useRouter()
	const session = useSession()

	return (
		<>
			<Nav>
				<Link href='/'>
					<LogoWrapper>
						<Logo />
					</LogoWrapper>
				</Link>
				<Flex css={{ padding: '0 1.25rem 0 0.25rem' }}>
					<Account />
					<ThemeToggle />
					<TransparentButton>
						<MenuIcon />
					</TransparentButton>
				</Flex>
				<Flex>
					<CreateButton onClick={() => router.push('/create')}>
						<Plus />
					</CreateButton>
				</Flex>
			</Nav>
			{session.status === 'authenticated' && <Credits />}
		</>
	)
}
