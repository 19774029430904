
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { darkTheme } from 'ui'
import { useLayoutEffect } from 'react'


export const themeAtom = atomWithStorage('theme', 'dark')

export function useTheme() {
  const [theme, setTheme] = useAtom(themeAtom)

  useLayoutEffect(() => {
    const body = document.querySelector('body')
    if (body && theme) {
      body.classList.remove('light', darkTheme?.className)
      // @ts-ignore
      body.classList.add(theme?.className || theme)
    }
  }, [theme])

  const toggleTheme = () => setTheme(theme === 'light' ? darkTheme : 'light')
  const currentTheme = theme === 'light' ? 'light' : 'dark'
  return { theme, toggleTheme, currentTheme }
}