import { useState, useEffect, useMemo } from 'react'
import { styled } from 'ui'

function getRandomInt(max: number) {
	return Math.floor(Math.random() * max)
}

const StarSVG = styled('svg', {
	position: 'absolute',
	maxWidth: '100%',
	maxHeight: '100vh',
	top: 0,
	left: 0,
	zIndex: -1,
})

export function Stars({
	stars = 75,
	width,
	height,
}: {
	stars?: number
	width: number
	height: number
}) {
	const initialEllipses = useMemo(
		() =>
			Array(stars)
				.fill(null)
				.map(() => ({
					cx: getRandomInt(width),
					cy: getRandomInt(height),
					rx: getRandomInt(2),
					ry: getRandomInt(2),
					opacity: Math.random() - 0.05,
				})),
		[stars, width, height]
	)

	const [ellipses, setEllipses] = useState(initialEllipses)

	useEffect(() => {
		const intervalId = setInterval(() => {
			setEllipses(
				ellipses.map((ellipse) => ({
					...ellipse,
					opacity: Math.random(),
				}))
			)
		}, 1250)
		return () => clearInterval(intervalId)
	}, [ellipses, height, width])

	return (
		<StarSVG css={{ height, width }}>
			{ellipses.map((ellipse, index) => (
				<ellipse
					key={`${ellipse.cx}-${ellipse.cy}-${index}`}
					cx={ellipse.cx}
					cy={ellipse.cy}
					rx={ellipse.rx}
					ry={ellipse.ry}
					fill='#F4D06F'
					style={{
						opacity: ellipse.opacity,
						transition: 'opacity 1s ease-in-out',
					}}
				/>
			))}
		</StarSVG>
	)
}
