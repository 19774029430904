import { trpc } from '@utils/trpc'
import { useState } from 'react'
import { styled, Modal, Button } from 'ui'

const CreditWrapper = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '0.25rem',
	width: 'max-content',
	position: 'fixed',
	right: '2rem',
	top: '2rem',
	padding: '.25rem .5rem',
	fontSize: '0.75rem',
	borderRadius: '$default',
	color: '$white',
	background:
		'linear-gradient(45deg, #2764FF 0.85%, #5F27FF 60.68%, #F4D06F 120.63%);',
})

const Form = styled('form', {
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
	marginTop: '2rem',
})

const LightningIcon = () => (
	<svg
		width='12'
		height='12'
		viewBox='0 0 12 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<g clipPath='url(#clip0_223_391)'>
			<path
				d='M6.5 1L1.5 7H6L5.5 11L10.5 5H6L6.5 1Z'
				stroke='white'
				strokeWidth='1.2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
		<defs>
			<clipPath id='clip0_223_391'>
				<rect width='12' height='12' fill='white' />
			</clipPath>
		</defs>
	</svg>
)

export const Credits = () => {
	const [credits, setCredits] = useState(0)
	const { data } = trpc.user.get.useQuery()
	const utils = trpc.useContext()
	const creditCount = data?.Subscription[0]?.credits ?? 0

	const { mutate, isLoading } = trpc.subscription.addCredits.useMutation({
		onSuccess: () => utils.user.get.invalidate(),
	})

	return (
		<Modal
			trigger={
				<CreditWrapper>
					<LightningIcon />
					{creditCount}
				</CreditWrapper>
			}
			title='Credits'
			description='Credits are used to create new projects. You can earn credits by inviting friends to use the app.'>
			<div>Invite friends to earn credits</div>
			{/* Form to add credits to account */}
			<Form
				onSubmit={(e) => {
					e.preventDefault()
					return mutate({ credits })
				}}>
				<input
					type='text'
					value={credits}
					onChange={(e) => setCredits(Number(e.target.value))}
				/>
				<Button type='submit' disabled={isLoading || credits === 0}>
					Add Credits
				</Button>
			</Form>
		</Modal>
	)
}
