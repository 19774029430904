import React, { FC } from 'react'
import { styled } from 'ui'
import { Header } from './header'

const LayoutWrapper = styled('div', {
	width: '100%',
	minHeight: '100vh',
	backgroundColor: '$background',
	color: '$text',
	transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
})

export const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<LayoutWrapper>
			<Header />
			{children}
		</LayoutWrapper>
	)
}
