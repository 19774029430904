import React from 'react'
import { styled } from 'ui'

interface Props {
	icon: React.ReactNode
	children: string
	badge?: React.ReactNode
	active?: boolean
	disabled?: boolean
	onClick?: () => void
}

const Wrapper = styled('div', {
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr max-content',
	alignItems: 'center',
	gap: '0.75rem',
	width: '100%',
	height: '100%',
	color: '$text',
	padding: '.5rem .75rem',
	opacity: '.66',
	borderRadius: '3px',
	transition: 'all .2s ease',
	cursor: 'pointer',
	'&:hover': {
		opacity: '.9',
	},
	variants: {
		active: {
			true: {
				opacity: '1',
				background:
					'linear-gradient(134.95deg, rgba(39, 100, 255, 0.2) 25.02%, rgba(95, 39, 255, 0.2) 100%)',
			},
		},
		disabled: {
			true: {
				cursor: 'not-allowed',
			},
		},
	},
})

const Badge = styled('div', {
	opacity: '.33',
})

export const SideNavItem = ({
	icon,
	children,
	badge,
	active,
	onClick,
	disabled,
}: Props) => {
	return (
		<Wrapper
			disabled={disabled}
			active={active}
			onClick={onClick}
			role={onClick ? 'button' : ''}>
			{icon}
			<div>{children}</div>
			{badge && <Badge>{badge}</Badge>}
		</Wrapper>
	)
}
