import React from 'react'
import { styled } from 'ui'

type Props = {
	children: React.ReactNode
	sidebar?: React.ReactNode
}

const Wrapper = styled('div', {
	width: 'calc(100% - 320px)',
	minHeight: '100vh',
	marginLeft: '320px',
	padding: '0 4rem',
})

const SidebarWrapper = styled('div', {
	position: 'fixed',
	left: 0,
	top: 0,
	width: '100%',
	maxWidth: '320px',
	padding: '7.5rem 1.5rem 2rem',
	minHeight: '100vh',
	display: 'flex',
	flexDirection: 'column',
	background: '$transparentGradient',
})

export const Sidebar = ({ children, sidebar }: Props) => {
	return (
		<Wrapper>
			<SidebarWrapper>{sidebar}</SidebarWrapper>
			{children}
		</Wrapper>
	)
}
